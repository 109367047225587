import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { H1 } from '@/components/Heading';
import { NextImage } from '@/components/Image';
import NavLink from '@/components/UI/Links/NavLink';

import RoutePath from '@/constants/route-path';

import breakpoints from '../../../themes/betterstyle-theme/breakpoints';

type MaintenanceProps = {
    title?: string | ReactNode;
    message?: string | ReactNode;
};

export const Maintenance = (props: MaintenanceProps) => {
    const { message, title } = props;

    const [isDesktop] = useMediaQuery(`(min-width: ${breakpoints.xl})`, {
        ssr: true,
        fallback: true
    });

    const [desktop, setDesktop]: [
        boolean | undefined,
        Dispatch<SetStateAction<boolean | undefined>>
    ] = useState();

    useEffect(() => {
        setDesktop(isDesktop);
    }, [isDesktop]);

    return (
        <Box
            as="section"
            py={12}
            textAlign="center"
            minH="60vh"
            overflow="hidden"
            pos="relative"
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <Box
                pos="relative"
                zIndex={10}
                bgColor="rgba(254, 254, 254, 0.75)"
                borderRadius="30px"
                px={{ base: '50px', md: '25px', xl: '50px' }}
                py="30px"
            >
                <Box pb={desktop ? 10 : 6}>
                    <Text
                        fontSize={{ base: '3.75rem', md: '5rem', lg: '11.3rem' }}
                        fontWeight="semibold"
                        lineHeight={1}
                        mb={6}
                    >
                        {title !== undefined ? title : <FormattedMessage id="ups" />}
                    </Text>
                    <H1 fontSize={{ base: '1rem', md: '1.25rem', lg: '1.5rem' }} mb={0}>
                        {message !== undefined ? message : <FormattedMessage id="page-not-found" />}
                    </H1>
                </Box>
                <NavLink href={RoutePath.Home} variant="buttonBlue">
                    <FormattedMessage id="back-to-homepage" />
                </NavLink>
            </Box>
            <Flex
                alignItems="center"
                justifyContent="center"
                position={{ base: 'absolute', md: 'static', xl: 'absolute' }}
                transform={{
                    base: 'translateX(-50%)',
                    md: 'translateX(0)',
                    xl: 'translateX(-50%)'
                }}
                left="50%"
                top={0}
                width="100%"
                height="100%"
                pointerEvents="none"
            >
                <NextImage
                    src="/images/404-bg.webp"
                    width={desktop ? 1920 : 800}
                    height={desktop ? 1200 : 650}
                    alt="404"
                    style={{
                        objectFit: desktop ? 'contain' : 'cover',
                        width: desktop ? 'auto' : '100%',
                        height: desktop ? 'auto' : '100%'
                    }}
                />
            </Flex>
        </Box>
    );
};
