import { Grid, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import NavLink from '@/components/UI/Links/NavLink';

import RoutePath from '@/constants/route-path';

export const RegisterEncourage = () => {
    return (
        <Grid mt={8} justifyContent="center">
            <Text fontWeight="bold">
                <FormattedMessage id="page.login.encourage.text" />
            </Text>
            <UnorderedList mb={4} pl={6}>
                <ListItem>
                    <FormattedMessage id="page.login.encourage.list-item-1" />
                </ListItem>
                <ListItem>
                    <FormattedMessage id="page.login.encourage.list-item-2" />
                </ListItem>
                <ListItem>
                    <FormattedMessage id="page.login.encourage.list-item-3" />
                </ListItem>
            </UnorderedList>
            <NavLink
                variant="buttonBlue"
                fontWeight="semibold"
                mx="auto"
                href={RoutePath.Register}
                borderRadius={21}
                px={4}
                py={2}
                _hover={{
                    bgColor: 'blue.600',
                    textDecoration: 'underline'
                }}
            >
                <FormattedMessage id="page.login.create-account" />
            </NavLink>
        </Grid>
    );
};
